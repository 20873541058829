
import React, { Component } from 'react'
//import {Paper} from '@mui/material'
import { Avatar, Box, Container,Link, Paper,Modal ,Typography ,MenuItem,IconButton,Button, Divider, AppBar, Card} from '@mui/material'
import man from '../src/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';

import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport';
import PageviewIcon from '@mui/icons-material/Pageview';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SettingsIcon from '@mui/icons-material/Settings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
export class Appheader extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       open:false,
       data:"",
       textcolor:"#33339c",
       bgcolor:"",
 
       id:1,
       pathname:this.props.location.pathname,
 
       opencompaign:false,
       openrole:false,

    }
  }


  




  render() {
    return (
      <div>
      <Container maxWidth='lg'>
<Paper elevation={1}  sx={{height:60,backgroundColor:'#fff',borderRadius:2,position:'fixed',width:'100%',marginLeft:{xs:-2,sm:0}}}>
<Box sx={{display:'flex',justifyContent:'space-between',backgroundColor:'#fff',borderRadius:2}}>


<Box onClick={()=>this.setState({open:true})} sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>
<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ ml:1,display:{xs:'block',sm:'none',mt:2} }}
        >
          <MenuIcon sx={{}} />
        </IconButton>

</Box>



<Box sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>



<Box sx={{marginRight:{xs:1,sm:35},display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
<Box>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:13,fontFamily:'sans-serif',fontWeight:'700'}}>M-TEL</Typography>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:11,fontFamily:'sans-serif',color:'#42526e'}}>Admin</Typography>
</Box>

</Box>


</Box>



</Box>
</Paper>
</Container>




<Box sx={{display:{xs:'block',sm:'none'}}}>
<Modal
  open={this.state.open}
  sx={{
    display:{xs:'block',sm:'none'}
  }}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'left',alignItems:'center',height:'100%',width:'100%'}} onClick={()=>this.setState({open:false})}>
<Paper sx={{width:'70%',height:'100vh',backgroundColor:'white',borderRight:3,borderColor:'#b0b0b0'}}>


<br/>
<br/>
<br/>





<Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/dashboard" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/dashboard')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DashboardIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/dashboard" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/dashboard" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Dashboard
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/users" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/users')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <GroupIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/users" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/users" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Users
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/settings" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/settings')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SettingsIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/settings" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/settings" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Settings
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/notification" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/notification')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  
                  <NotificationsNoneIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/notification" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/notification" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Notification
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/alerts" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/alerts')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <CampaignIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/alerts" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/alerts" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Alerts
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/contact" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/contact')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <ContactPhoneIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/contact" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/contact" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Contacts
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/enquiry" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/enquiry')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DynamicFormIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/enquiry" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/enquiry" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Enquiry
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>







</Paper>
</Box>
</Modal>
</Box>


    </div>
    )
  }
}

export default Appheader

export function Appheaderc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Appheader location={location} navigate={navigate}></Appheader>)
}


