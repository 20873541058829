import logo from './logo.svg';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Users from './Users';
import Settings from './Settings';
import Notification from '../src/Notification'
import Alerts from '../src/Alerts'
import Login from './Login';
import Contact from './Contact';
import Enquiry from './Enquiry';


function App() {
let is_login = sessionStorage.getItem('is_login')


  return (
    <Box sx={{backgroundColor:'#f8f9ff'}}>
    <React.StrictMode>
    <RouterProvider router={createBrowserRouter([
    {
      path: "/" ,
      element:<Login/>,
    },
  {
    path:is_login=='true'? "/dashboard" : '/',
    element:<Dashboard/>,
  },
  {
    path:is_login=='true'? "/users" : "/",
    element:<Users/>,
  },
  {
    path:is_login=='true'? "/settings":'/',
    element:<Settings/>,
  },
  {
    path: is_login=='true'? "/notification" : '/',
    element:<Notification/>,
  },
  {
    path:is_login=='true'? "/alerts" :'/', 
    element:<Alerts/>,
  },

  {
    path:is_login=='true'? "/contact" :'/', 
    element:<Contact/>,
  },
  {
    path:is_login=='true'? "/enquiry" :'/', 
    element:<Enquiry/>,
  },
])} />

</React.StrictMode>
</Box>

  );
}

export default App;
