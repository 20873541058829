import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import base_url from "./baseurl";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ToastContainer, toast } from "react-toastify";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";




const drawerWidth = 240;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayLogin: 0,
      totalLoginIn15days: 0,
      totalLoginIn30days: 0,
      allusers: 0,


      login7days : 0,

    
    };
  }

  componentDidMount() {




    fetch(`${base_url.base_url}/daysLoginCount`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          todayLogin: result.todayLogin,
          totalLoginIn15days: result.totalLoginIn15days,
          totalLoginIn30days: result.totalLoginIn30days,
          allusers: result.allusers,
          login7days : result.login7days
        });
      });



  }

  succes = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Employee Successfully Added
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldsdeleted = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Employee deleted{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  empupdated = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Employee updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Dashboard
              </Typography>

              <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL TODAY LOGIN
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.todayLogin}
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL LOGIN IN 15 DAYS
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.totalLoginIn15days}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL LOGIN IN 30 DAYS
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                       {this.state.totalLoginIn30days}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                      >
                       TOTAL USERS
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                       {this.state.allusers}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Box>
                <Paper
                  elevation={1}
                  style={{
                    height: 310,
                    width: "100%",
                    marginTop: 15,
                    backgroundColor: "#fff",
                  }}
                >
                  <Chart
                    options={{
                      chart: {
                        id: "apexchart-example",
                      },
                      xaxis: {
                        categories: ['today trend','7 days trend','15 days trend','30 days trend'],
                      },
                    }}
                    series={[
                      {
                        name: "series-1",
                        data: [this.state.todayLogin,this.state.login7days,this.state.totalLoginIn15days,this.state.totalLoginIn30days],
                      },
                    ]}
                    type="area"
                    height={300}
                  />
                </Paper>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 240 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>
      </div>
    );
  }
}

export default Dashboard;
